// convertir les px en em/rem:
@function pem($pxval, $base: 16, $unit: rem) {
	@return #{$pxval / $base}#{$unit};
}

// Depreceted
@mixin center-absolute($width, $unit: 'px', $fixed: false) {
	@if $fixed == false {
		position: absolute;
	}
	@else {
		position: fixed;
	}

	left: 50%;
	width: #{$width}#{$unit};
	margin-left: -#{$width/2}#{$unit};
}

// center de manière traditionnel
@mixin centrer($width: 62.5em) {
    width: $width;
    margin: 0 auto;
}

// Centrer un élément css absolu
// la structure html est la suivante
//
// <div class="container">
//   <div class="relative-center">
//    <img alt="city" src="images/header-city.png" />
//   </div>
// </div>
@mixin centrer_absolute() {
	.container {
		position: absolute;
		bottom: 0;
		left: 50%;
		.relative-center {
			position: relative;
			left: -50%;
		}
	}
}

@mixin italic() {
    .italic {
        font-style: italic;
    }
}

// Récupération de foundation
// https://github.com/zurb/foundation-sites/blob/develop/dist/foundation.css#L392
@mixin img_responsive() {
	img {
		max-width: 100%;
		height: auto;
		-ms-interpolation-mode: bicubic;
		display: inline-block;
		vertical-align: middle;
	}
}

// Class clearfix reprise de foundation
@mixin clearfix() {
	.clearfix {
		&:before, &:after {
					  content: ' ';
					  display: table;
				  }
		&:after {
			clear: both;
		}
	}
}

// Cibler tout les header d'un seul coup
// voir : https://gist.github.com/mattts/8279105
@mixin headers {
	@for $index from 1 through 6 {
		h#{$index} {
			@content;
		}
	}
}

// Gestion de la taille et de la couleur des placeholder
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}
