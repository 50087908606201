@charset 'UTF-8';

@import 'sassix/sassix';

body {
  padding-top: rem-calc(20);
}

.titre {
  @extend h1;
  color: $primary-color;
  text-transform: uppercase;
  font-size: rem-calc(37);
}

.slogan {
  font-family: $header-font-family;
  font-size: rem-calc(20);
}

@include headers {
  text-transform: uppercase;
}

.main {
  margin-top: rem-calc(50);
  @include headers {
  }
  h1 {
    color: $primary-color;
    font-size: rem-calc(30);
  }
  h2 {
    color: #aa8f67;
    font-size: rem-calc(26);
  }

  h3 {
    font-size: rem-calc(24);
  }

  h3, h4 {
    font-family: $body-font-family;
  }

  h4 {
    text-transform: none;
    font-size: rem-calc(20);
  }
}

.chapo {
  font-weight: bold;
}

.discipline {

  .column {
    margin-bottom: rem-calc(15);
  }

  h3 {
    font-size: rem-calc(14);
    font-weight: bold;
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(20);
    font-family: $header-font-family;
  }

  .button {
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);
  }
}

.sidebar {
  box-shadow: 0px 1px 5px 0px rgba(50, 50, 50, 0.55);
  margin-bottom: rem-calc(20);

  .callout {
    margin-bottom: rem-calc(45);
  }

  @include headers {
    color: $primary-color;
  }

  h2 {
    font-weight: bold;
    font-size: rem-calc(32);
    margin-top: rem-calc(25);
    margin-bottom: rem-calc(32);
  }

  h3 {
    font-weight: bold;
    font-size: rem-calc(20);
    font-family: $header-font-family;
  }

  .question {
    margin-top: rem-calc(50);
    p {
      text-transform: uppercase;
      font-family: $header-font-family;
    }
  }
}

// Centrer la topbar principal
.top-bar-centered {

  // On étend simplement une topbar existante
  @extend .top-bar-left;
  float: none; // Pas besoin de flotter

  &> .menu.large-horizontal {
    // Centrer le menu
    text-align: center;

    li {
      // On changer le style de display Pour que text-align fonctionne, il doit
      // être appliqué a un inline
      display: inline-block;

      // On ne centre pas les sous-menu
      ul {
        text-align: left;
      }
    }
  }
}

.top-bar {
  height: rem-calc(50px);

  li {
    &.active {
      margin-bottom: -5px;
      &:after {
        @include css-triangle(rem-calc(5), $white, up);
        margin: 0 auto;
      }
    }

    a {
      font-size: rem-calc(16);
      color: #FFF;
      font-weight: bold;
    }
  }

  li li.is-submenu-item:hover {
    background-color: $white;
    &> a {
      color: $primary-color;

      &::after {
        border-color: transparent transparent transparent $primary-color;
      }
    }
  }

  .is-drilldown {
    border: {
      top: none;
      left: none;
      right: none;
      bottom: 1px solid $white;
    }
  }

  .is-drilldown-submenu {
    height: 100%;
  }

  ul ul {
    @include menu-base;
  }
}

#index .palai {

  height: rem-calc(454);
  @include breakpoint(xlarge down) {
    height: rem-calc(200);
  }

  background: url(../images/palai.png) no-repeat center center;
  background-size: cover;
}

.footer_line {
  position: relative;
  margin-top: rem-calc(5);
  height: rem-calc(5);
  background: $primary-color;
  margin-bottom: rem-calc(50);

  .separateur_footer {
    position: absolute;
    top: rem-calc(-4);
    background: url(../images/separateur_footer.png) no-repeat center center;
    width: 100%;
    height: 13px;
  }
}

footer {
  p {
    font-weight: bold;
    font-family: $header-font-family;
    font-size: rem-calc(14);
  }
}

.callout {
  &.primary {

    @include headers {
      color: $white;
    }
    position: static;
    font-size: rem-calc(18);
    margin-bottom: rem-calc(50);
    h1 {
      margin-bottom: rem-calc(30);
    }
  }
  &.secondary {
    color: $black;
    border: none;
  }
}

.button {
  text-transform: uppercase;
  font-family: $header-font-family;
}
